<template>
  <div>
    <div class="d-flex">
      <nav id="breadcrumbs" class="mb-3">
        <ul>
          <li>
            <router-link :to="{ name: 'company.dashboard' }">
              <a href="#"> <i class="uil-home-alt"></i> </a>
            </router-link>
          </li>
          <li>
            <span> {{
              this.$t('report.company_dashboard.reports') }}
            </span>
          </li>
          <li>{{ $t('general.end_of_training_detailed_evaluation_report') }}</li>
        </ul>
      </nav>
    </div>

    <div class="section-header mb-lg-2 border-0 uk-flex-middle">
      <div class="section-header-left">
        <h2 class="uk-heading-line text-left">
          <span>{{ $t('general.end_of_training_detailed_evaluation_report') }}</span>
        </h2>
      </div>
    </div>
    <!--Modal Profie-->

    <div v-if="error" class="uk-alert-danger mb-3" uk-alert>
      <a class="uk-alert-close" uk-close></a>
      <p style="color: red">
        <i class="uil-exclamation-triangle"></i> {{ error }}
      </p>
    </div>

    <!--End Modal Profile -->
    <div class="card">
      <!-- Card header -->
      <div class="card-header actions-toolbar border-0">
        <div class="d-flex justify-content-between align-items-center">
          <!-- <h4 class="d-inline-block mb-0"></h4> -->
          <div class="row" style="width:100%">
            <div class=" col-md-1  search-icon ">
              <i class="uil-search"></i>
            </div>
            <div class="col-md-11 uk-search uk-search-navbar" style="margin-left:-2rem">
              <input class="uk-search-input shadow-0 uk-form-small" type="search" v-model="filter.search"
                :placeholder="$t('general.search')" autofocus="" />
            </div>
          </div>

          <div class="d-flex">
            <a :uk-tooltip="$t('filters.order.sort')" aria-expanded="false" class="btn btn-icon btn-hover btn-circle"
              href="javascript:void(0)" title="">
              <i class="uil-list-ui-alt"></i>
            </a>
            <div class="uk-dropdown" uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up">
              <ul class="uk-nav uk-dropdown-nav">
                <li class="uk-active">
                  <a href="javascript:void(0)" @click="sortOrder('asc', 'name')">{{ $t("sorting.a_z") }}</a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="sortOrder('desc', 'name')">{{ $t("sorting.z_a") }}</a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="sortOrder('asc', 'created_at')">
                    {{ $t("sorting.created_at_old") }}</a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="sortOrder('desc', 'created_at')">
                    {{ $t("sorting.created_at_new") }}</a>
                </li>
              </ul>
            </div>

            <!-- Filter -->
            <a class="btn btn-icon btn-hover btn-circle" href="javascript:void(0)"
              :uk-tooltip="$t('general.filter_list')">
              <i class="uil-filter"></i>
            </a>
            <div id="filterDropdown" class="large"
              uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up">
              <div class="filter-container">
                <div class="uk-child-width-1-2@s uk-grid-small" uk-grid>

                  <div>
                    <h5 class="uk-text-bold mb-2">Eğitim</h5>
                    <multiselect v-model="assignment_id" :allow-empty="true" :options="assignmentList"
                      :searchable="true" :multiple="true" deselectLabel="" label="name" placeholder="Eğitim Seçiniz"
                      selectLabel="Seçiniz" tag-placeholder="Eğitim Seçiniz" track-by="id"
                      @input="getUsers(assignment_id)">
                      <template slot="option" slot-scope="option">
                        {{ option.option.name }}
                      </template>
                    </multiselect>
                  </div>
                  <div>
                    <h5 class="uk-text-bold mb-2">Kullanıcı</h5>
                    <multiselect v-model="user_id" :allow-empty="true" :options="userList" :searchable="true"
                      :multiple="true" deselectLabel="" label="name" placeholder="Kullanıcı Seçiniz"
                      selectLabel="Seçiniz" tag-placeholder="Kullanıcı Seçiniz" track-by="id" />
                  </div>
                  <div class="uk-flex uk-flex-right p-2">
                    <button class="btn btn-default grey" @click="filterResult">
                      <i class="uil-filter"></i> {{ $t("general.filter") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <a class="btn btn-icon btn-hover btn-circle" href="javascript:;" :uk-tooltip="$t('filters.export_excell')"
              @click="exportUserAssignment">
              <i v-if="!isReportDownloading" class="fa-file-excel fa"></i>
              <span v-if="isReportDownloading" uk-spinner="ratio: 0.8"></span>
            </a>
          </div>
        </div>
      </div>
      <!-- Table -->
      <default-loading v-if="user_loading"></default-loading>
      <template v-else>
        <div class="table-responsive">
          <table class="table align-items-center">
            <thead>
              <tr>
                <th scope="col">{{ this.$t('general.assignment_name') }}</th>
                <th scope="col">{{ this.$t('general.first_name_and_last_name') }}</th>
                <th scope="col">Eposta</th>
                <th scope="col">{{ $t('general.register_number') }}</th>
                <th scope="col">Telefon</th>
                <th scope="col">Değerlendirme Sorusu/Cevabı</th>
                <th scope="col">{{ $t('general.average_point') }}</th>
                <th scope="col">Cevaplama Tarihi</th>

              </tr>
            </thead>

            <tbody class="list">
              <template v-for="item in formattedItems">
                <tr>
                  <td>{{ item.assignment_name }}</td>
                  <td> {{ item.user_name }}</td>
                  <td>{{ item.user_email }}</td>
                  <td>{{ item.user_registration_number }}</td>
                  <td>{{ item.user_phone }}</td>
                  <td>
                    <p v-for="question in item.survey_questions">
                      {{ question.question }}<br> <b>CEVAP :
                        {{ question.answer }}</b>
                    </p>
                  </td>
                  <td>{{ item.survey_average }}</td>
                  <td>{{ item.survey_answer_date }}</td>

                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <pagination :pagination="pagination" class="mt-2 mb-3" @changePage="setPage($event)"></pagination>
      </template>
    </div>
  </div>
</template>

<script>
import reports, {
  ERROR,
  GET_ITEMS,
  ITEMS,
  LOADING,
  MODULE_NAME,
  SUCCESS,
} from "@/core/services/store/reports.module";

import store from "@/core/services";
import moment from "moment";
import axios from "axios";
import UserProfile from "@/view/company/User/UserProfile";
import UserFilter from "@/view/company/User/Filter";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import Pagination from "@/assets/components/Pagination";
import Multiselect from "vue-multiselect";

const _MODULE_NAME = MODULE_NAME;

export default {
  name: "EducationEndDetailEvaluationReport",
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(_MODULE_NAME, reports);
  },
  components: {
    Pagination,
    UserProfile,
    UserFilter,
    DefaultLoading,
    Multiselect,
  },
  data() {
    return {
      pagination: null,
      user_id: null,
      assignment_id: [],
      error: null,
      page: 1,
      per_page: 10,
      sort: "id",
      order: "desc",
      formattedItems: [],
      userList: [],
      assignmentList: [],
      isReportDownloading: false,
      filter: {
        search: null,
        order: "desc",
        sort: "id",
      },
      successMessage: "",
    };
  },
  computed: {
    items: {
      get() {
        return store.getters[_MODULE_NAME + "/" + ITEMS];
      },
      set(value) {
      },
    },
    user_loading: {
      get() {
        return store.getters[_MODULE_NAME + "/" + LOADING];
      },
      set(value) {
      },
    },
    errors: {
      get() {
        return store.getters[_MODULE_NAME + "/" + ERROR];
      },
      set(value) {
      },
    },
    success() {
      return store.getters[_MODULE_NAME + "/" + SUCCESS];
    },
  },
  methods: {
    userStatus(statusId) {
      switch (statusId) {
        case 0:
          return "Pasif";
        case 1:
          return "Aktif";
      }
    },
    getUsers(assignment_id) {

      let assignment_ids = [];

      assignment_id.forEach(function (r) {
        assignment_ids.push(r.id)
      })

      let filters = {
        per_page: 0,
        sort: "id",
        order: "desc",
        fields: "id,name,surname",
        assignment_id: assignment_ids.toString(),
      };

      store
        .dispatch(_MODULE_NAME + "/" + GET_ITEMS, {
          url: "api/user",
          filters: filters,
        })
        .then((response) => {
          if (response.status) {

            let results = [
              {
                id: "all",
                name: "Tümü",
              },
            ];

            let items = response.data;

            items.forEach((item) => {
              results.push({
                id: item.id,
                name: item.name + " " + item.surname,
              });
            });
            this.userList = results;
          }
        });
    },
    exportUserAssignment() {
      let self = this;

      if (+self.assignment_id.length === 0) {
        this.error = this.$t("validation.please_select_assignment");
        return false;
      }

      this.isReportDownloading = true;

      let ids = [];
      // UIkit.dropdown("#filterDropdown").hide();
      self.assignment_id.forEach((items) => {
        ids.push(items.id);
      });

      axios
        .get(
          process.env.VUE_APP_BACKEND_APP_URL +
          "/api/report/export/user-assignments-detail?sort=" +
          self.sort +
          "&order=" +
          self.order +
          "&assignment_ids=" +
          ids.toString() + "&report=" + Math.random() * 9999999,
          {
            responseType: "blob",
            headers: this.apiHeaders,
          }
        )
        .then((response) => {
          let fileURL = window.URL.createObjectURL(new Blob([response.data]));
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          let day = moment().format("YYYYMMDDHHMMSS");
          let fileName = "user_assignment_report_list_" + day + ".xlsx";
          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.isReportDownloading = false;
        });
    },
    filterResult(filter = null) {
      let self = this;
      this.error = null;

      if (+self.assignment_id.length === 0) {
        this.error = this.$t("validation.please_select_assignment");
        return false;
      }

      let ids = [];

      // UIkit.dropdown("#filterDropdown").hide();
      self.assignment_id.forEach((items) => {
        ids.push(items.id);
      });


      let user_ids = [];

      // UIkit.dropdown("#filterDropdown").hide();

      if (self.user_id !== null && self.user_id.length > 0) {
        self.user_id.forEach((item) => {
          user_ids.push(item.id);
        });
      }


      let filters = {
        like: self.filter.search,
        assignment_id: JSON.stringify(ids),
        user_id: JSON.stringify(user_ids),
        page: self.page,
        per_page: self.per_page,
        order: self.order,
        sort: self.sort,
      };
      store
        .dispatch(_MODULE_NAME + "/" + GET_ITEMS, {
          url: "api/report/user-assignments-detail",
          filters: filters,
        })
        .then((response) => {
          if (response.status) {
            let results = [];
            let responseData = response.data;
            this.pagination = responseData;

            responseData.data.forEach((item) => {

              results.push({
                'user_name': item.user_name,
                'user_email': item.user_email,
                'user_phone': item.user_phone,
                'user_registration_number': item.user_registration_number,
                'survey_questions': item.survey_questions,
                'survey_answer_date': item.survey_answer_date,
                'assignment_name': item.assignment_name,
                'survey_average': item.survey_average,
              });
            });

            this.formattedItems = results;
          }
        });
    },
    getAssignments() {
      let self = this;
      let filters = {
        page: 0,
        sort: "id",
        order: "desc",
        per_page: this.per_page,
        evaluation_survey: 1
      };
      store
        .dispatch(_MODULE_NAME + "/" + GET_ITEMS, {
          url: "api/report/get-assignments",
          filters: filters,
        })
        .then((response) => {
          self.assignmentList = [
            {
              id: "all",
              name: "Tümü",
            },
          ];
          if (response.status) {
            let results = [];
            let items = response.data;
            items.forEach((item) => {
              self.assignmentList.push({
                id: item.id,
                name: item.full_name,
                order_number: item.order_number,
              });
            });
            //this.assignmentList = results;
          }
        });
    },
    sortOrder(order, sort) {
      this.page = 1;
      this.order = order;
      this.sort = sort;

      this.filterResult();
    },
    setPage(page) {
      this.page = page;
      this.filterResult();
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    getFilter(value) {
      this.page = 1;
      this.filterResult(value);
    },
  },
  mounted() {
    this.filterResult();
    this.getAssignments();
    this.debouncedFilterSearch = _.debounce(this.filterResult, 500);
    if (this.$route.params.succesMessage != null) {
      this.successMessage = this.$route.params.succesMessage;
      this.scrollToTop();
    }
  },
  watch: {
    success: function (val) {
      if (val) {
        this.filterResult();
      }
    },
    "filter.search": function (val) {
      this.debouncedFilterSearch();
    },
  },
  beforeDestroy() {
    const modal = UIkit.modal("#modal-profile");
    if (modal) modal.$destroy(true);
  },
};
</script>

<style scoped>
@import "~vue-multiselect/dist/vue-multiselect.min.css";

input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}

.uk-alert {
  margin: 0;
}
</style>
<style>
.multiselect__tag {
  color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #3e416d;
}

.multiselect__tag-icon {
  background-color: transparent;
  line-height: 2.2;
}

.multiselect__tag-icon:after {
  color: #fff;
}

.multiselect__tag-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.multiselect__input {
  border: unset !important;
}

.multiselect__tags .multiselect__input {
  border: unset !important;
}

.uk-tooltip {
  word-wrap: break-word;
  font-size: 10px !important;
}
</style>
